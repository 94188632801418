// colors

import { createTheme } from "@mui/material";

export const primaryColor = "#3a92c9";
export const secondaryColor = "orange";
export const darkColor = "#212631";

export const antdTheme = {
  token: {
    colorPrimary: primaryColor,
  },
  components: {
    Button: {
    },
  },
};

export const themeMui = createTheme({
  palette: {
    primary: {
      main: primaryColor,
    },
  },
  typography: {
    fontSize: 13,
    button: {
      textTransform: "none",
    },
  },
});

import { IFormField } from "../../../utils/form_factory";

export const LoginFormFields = (): IFormField[] => {
  return [
    {
      name: "email",
      initailValue: "",
      label: "Email",
      type: "email",
      uiType: "email",
      uiBreakpoints: { xs: 12, sm: 12, md: 6, lg: 6, xl: 6 },
      required: true,
    },
    {
      name: "password",
      initailValue: "",
      label: "Password",
      type: "password",
      uiType: "password",
      uiBreakpoints: { xs: 12, sm: 12, md: 6, lg: 6, xl: 6 },
      required: true,
    },
  ];
};

import {
  Box,
  BottomNavigation,
  BottomNavigationAction,
  styled,
  useMediaQuery,
  Theme,
} from "@mui/material";
import { FC, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import topMenuList from "./MenuList";

// Root component interface
interface SideNavBarProps {
  showMobileSideBar: boolean;
  closeMobileSideBar: () => void;
}

// Custom styled components
const MainMenu = styled(Box)(({ theme }) => ({
  left: 0,
  width: 100, // Increase width to accommodate longer names
  height: "100%",
  position: "fixed",
  boxShadow: theme.shadows[2],
  transition: "left 0.3s ease",
  zIndex: theme.zIndex.drawer + 11,
  backgroundColor: theme.palette.primary.main,
  [theme.breakpoints.down("md")]: { left: -80 },
}));

const BottomMenu = styled(BottomNavigation)<{ hidden: boolean }>(
  ({ theme, hidden }) => ({
    width: "100%",
    position: "fixed",
    bottom: hidden ? "-64px" : "0",
    boxShadow: theme.shadows[2],
    zIndex: theme.zIndex.drawer + 11,
    backgroundColor: theme.palette.primary.main,
    transition: "bottom 0.3s ease",
  })
);

const DashboardSideBar: FC<SideNavBarProps> = ({
  showMobileSideBar,
  closeMobileSideBar,
}) => {
  const navigate = useNavigate();
  const [active, setActive] = useState("Dashboard");
  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const [menuHidden, setMenuHidden] = useState(false);
  const [lastScrollY, setLastScrollY] = useState(0);

  const handleActiveMainMenu = (menuItem: any) => () => {
    setActive(menuItem.title);
    navigate(menuItem.path);
    closeMobileSideBar();
  };

  // Scroll event listener
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      setMenuHidden(currentScrollY > lastScrollY);
      setLastScrollY(currentScrollY);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [lastScrollY]);

  const mainSideBarContent = (
    <Box sx={{ height: "100%" }}>
      {topMenuList.map((nav, index) => (
        <Box
          key={index}
          sx={{
            padding: 2,
            textAlign: "center",
            cursor: "pointer",
            color: active === nav.title ? "#fff" : "rgba(255, 255, 255, 0.7)",
            fontWeight: active === nav.title ? "bold" : "normal",
          }}
          onClick={handleActiveMainMenu(nav)}
        >
          <nav.Icon />
          <Box sx={{ fontSize: 10, fontWeight: "bold", marginTop: 1 }}>
            {nav.title}
          </Box>
        </Box>
      ))}
    </Box>
  );

  // Bottom menu for mobile
    if (downMd) {
      return (
        <BottomMenu
          value={active}
          onChange={(event, newValue) => {
            setActive(newValue);
            const selectedNav = topMenuList.find((nav) => nav.title === newValue);
            if (selectedNav) navigate(selectedNav.path);
          }}
          hidden={menuHidden}
          showLabels // Ensure labels are always visible
          sx={{
            "& .MuiBottomNavigationAction-root": {
              color: "rgba(255, 255, 255, 0.7)",
              minWidth: "0",
              flexGrow: 1,
              flexBasis: 0,
              padding: "1vw",
            },
            "& .MuiBottomNavigationAction-root.Mui-selected": {
              color: "#fff", // White color for active icon and label
              fontWeight: "bold",
            },
            "& .MuiBottomNavigationAction-label": {
              fontSize: "2.3vw",
              textAlign: "center",
            },
            "& .MuiBottomNavigationAction-label.Mui-selected": {
              fontWeight: "bold", // Ensure selected label is bold
              fontSize: "2.3vw", // Keep font size at 2.3vw for the selected item
            },
            "& .MuiSvgIcon-root": {
              fontSize: "5vw", // Consistent icon size
            },
          }}
        >
          {topMenuList.map((nav, index) => (
            <BottomNavigationAction
              key={index}
              label={nav.title}
              value={nav.title}
              icon={<nav.Icon />}
              sx={{
                color: active === nav.title ? "#fff" : "rgba(255, 255, 255, 0.7)",
              }}
            />
          ))}
        </BottomMenu>
      );
    }


  return <MainMenu>{mainSideBarContent}</MainMenu>;
};

export default DashboardSideBar;
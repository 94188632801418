import Slider from "react-slick";
import styled from "styled-components";
import { Debate1, Debate2, Debate3, Debate4, Debate5 } from "../../assets/svg/SvgIcons";

const settings = {
  // fade: true,
  autoplay: true,
  speed: 1000,
  pauseOnHover: false,
  autoplaySpeed: 6000,
  slidesToShow: 1,
  arrows: false,
  dots: true,
};

function InfoTile({ data }: any) {
  const { title, desc, image, width } = data;
  return (
    <Wrapper className="whiteColor flexCenter flexColumn textCenter gap20">
      <SliderImg src={image} alt="image" width={width} />

      <div className="flexCenter flexColumn gap20" style={{ maxWidth: 500 }}>
        <h2>{title}</h2>
        <div className="font13">{desc}</div>
      </div>
    </Wrapper>
  );
}

const infoData = [
  {
    image: Debate1,
    title: "Dynamic Debates",
    desc: (
      <p>
        Unleash the <b>power</b> of persuasive prowess as debaters take 
        center stage, captivating audiences with their eloquence and insight.
      </p>
    ),
  },
  {
    image: Debate2,
    title: "Thrilling Showdowns",
    desc: (
      <p>
        Step into the arena where intellect clashes, and ideas ignite; our debaters go 
        head-to-head in riveting showdowns that redefine the art of rhetoric.
      </p>
    ),
  },
  {
    image: Debate3,
    title: "Diverse Perspectives",
    desc: (
      <p>
        Experience the mosaic of ideas as our diverse debaters from across Africa engage
        in thought-provoking discussions that challenge and inspire.
      </p>
    ),
  },
  {
    image: Debate4,
    title: "Unleash Your Voice",
    desc: (
      <p>
        Join the symphony of opinions; Afritab empowers you to unleash your voice, making 
        every debate a platform for your ideas to resonate.
      </p>
    ),
  },
  {
    image: Debate5,
    title: "Real-time Brilliance",
    desc: (
      <p>
        Witness brilliance unfold before your eyes with Afritab's real-time updates, where 
        every point scored and every argument made is a stroke of debate genius.
      </p>
    ),
  },
];

export default function InfoSlider() {
  return (
    <>
      {/* Slider */}
      <div
        data-aos="fade-up"
        data-aos-duration="1500"
        style={{ height: "100%" }}
      >
        <Slider {...settings}>
          {infoData.map((val, key) => {
            return <InfoTile data={val} key={key} />;
          })}
        </Slider>
      </div>
    </>
  );
}

const Wrapper = styled.div`
  width: 100%;
  min-height: 600px;
  padding: 40px;
`;

const SliderImg = styled.img`
  width: ${(props) => (props.width ? props.width : "350px")};
  marginbottom: 20px;
  @media (max-width: 760px) {
    width: ${(props) => (props.width ? props.width : "100%")};
  }
`;

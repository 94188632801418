import { Box, Button, Stack } from "@mui/material";
import { useFormik } from "formik";
import { FC, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { styled } from "styled-components";
import FlexBox from "../../../components/FlexBox";
import { H1, Paragraph, Small } from "../../../components/Typography";
import useAuth from "../../../hooks/useAuth";
import { primary } from "../../../theme/themeColors";
import { LoginFormValidation } from "./LoginFormValidation";
import FormFactory from "../../../components/UI/FormFactory";
import { LoginFormFields } from "./LoginFormFields";
import LoadingButton from "@mui/lab/LoadingButton";
import Success from "../../../components/UI/FormComponents/Success";

const Login: FC = () => {
  const { login } = useAuth();
  const formRef = useRef<HTMLFormElement | null>(null);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const location = useLocation();

  const { message } = location.state || {};

  let navigate = useNavigate();
  const formFields = LoginFormFields();

  const initialValues = {
    password: "",
    email: "",
    remember: false,
  };

  const loginForm = useFormik({
    initialValues,
    validationSchema: LoginFormValidation,
    onSubmit: async (values: any) => {
      handleSubmit(values.email, values.password);
    },
  });

  const handleSubmit = async (email: string, password: string) => {
    setLoading(true);
    try {
      if (login) await login(email, password);
      setLoading(false);
      navigate("/dashboard");
    } catch (error: any) {
      setError(error.message);
      setLoading(false);
    }
  };

  return (
    <FlexBox
      data-aos="fade-up"
      data-aos-duration="800"
      sx={{
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
        height: { sm: "100%" },
        padding: "1rem",
      }}
    >
      <Box sx={{ padding: 0 }}>
        <Stack spacing={1}>
          <H1 fontSize={28} fontWeight={800} mb={3}>
            <span style={{ color: primary.main }}>Sign</span> In
          </H1>
          <H1 fontSize={16} fontWeight={700}>
            Welcome to Afritab!
          </H1>
          <Paragraph pb={3} fontSize={12}>
            Revolutionizing Debate
          </Paragraph>
        </Stack>

        <FlexBox justifyContent="space-between" flexWrap="wrap" my="1rem">
          <form ref={formRef} onSubmit={loginForm.handleSubmit}>
            {
              message && <Success message={message} />
            }
            <Box sx={{ width: "100%" }}>
              <FormFactory
                others={{ sx: { marginBottom: "0rem" }, columns: 1 }}
                formikInstance={loginForm}
                formFields={formFields}
                validationSchema={LoginFormValidation}
              />
            </Box>

            <Box sx={{ mt: 4 }}>
              {loading ? (
                <LoadingButton
                  loading
                  variant="contained"
                  sx={{ bgcolor: "#242649" }}
                >
                  Submitting...
                </LoadingButton>
              ) : (
                <Button
                  type="submit"
                  variant="contained"
                  sx={{ bgcolor: "#242649" }}
                >
                  Submit
                </Button>
              )}
            </Box>
          </form>
        </FlexBox>
        <Small
          margin="auto"
          mt={3}
          color="text.disabled"
          style={{ display: "block" }}
        >
          Don't have an account?{" "}
          <Link to="/register">
            <Small color="primary.main">Register Now</Small>
          </Link>
        </Small>
      </Box>
    </FlexBox>
  );
};

export default Login;

import Icons from "../../icons/sidebar";

export interface IMenuItem {
  title: string;
  Icon?: any;
  path: string;
  subMenu?: IMenuItem[];
  permissions?: string[];
  users?: string[];
}

const MenuList: IMenuItem[] = [
  {
    title: "Dashboard",
    Icon: Icons.UserGridIcon,
    path: "/dashboard",
    // users: [USER_TYPES.DEBATER, USER_TYPES.ADJUDICATOR, USER_TYPES.INSTITUTION, USER_TYPES.SYSTEM_USER],
  },
  {
    title: "Tournaments",
    Icon: Icons.EmojiEventsIcon,
    path: "/dashboard/tournaments",
  },
  {
    title: "Rankings",
    Icon: Icons.UserManagementIcon,
    path: "/dashboard/user-list",
  },
  {
    title: "Tickets",
    Icon: Icons.ConfirmationNumberIcon,
    path: "/dashboard/ticket-grid",
  },
  {
    title: "Master",
    Icon: Icons.AdminPanelSettingsIcon,
    path: "/admin/tournament",
    // users: [USER_TYPES.INSTITUTION, USER_TYPES.SYSTEM_USER],
    // subMenu: [
    //   {
    //     path: "/admin/tournament",
    //     title: "Tournaments",
    //   },
    // ],
  },
  // SYSTEM USER ONLY
  {
    title: "Settings",
    Icon: Icons.Settings,
    // path: "/settings",
    path: "/institutions",
    // users: [USER_TYPES.SYSTEM_USER]
    // subMenu: [
    //   {
    //     title: "Institutions",
    //     path: "/institutions",
    //   },
    //   {
    //     path: "/users",
    //     title: "Users",
    //   },
    // ],
  },
];

export default MenuList;

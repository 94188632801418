import instance from "../../../api";
export const ClientService = {
  async getClients(filters: Object) {
    console.log(filters);
    return instance
      .get("users/", { params: filters })
      .then((response) => response.data);
  },

  async createClient(payload: Object) {
    console.log(payload);
    return instance.post("users/", payload).then((response) => response.data);
  },

  async getClientDetails(id: string) {
    console.log(id);
    return instance.get(`users/${id}/`).then((response) => response.data);
  },

  async updateClient(payload: Object, id: string) {
    console.log(payload);
    return instance
      .patch(`users/${id}/`, payload)
      .then((response) => response.data);
  },

  async getClientAccounts(filters: Object) {
    console.log(filters);
    return instance
      .get("client-accounts/", { params: filters })
      .then((response) => response.data);
  },

  async getBankAccounts(filters: Object) {
    return instance
      .get("account-bank/", {
        params: filters,
      })
      .then((response) => response.data);
  },

  async getCashAccounts(filters: Object) {
    return instance
      .get("account-cash/", {
        params: filters,
      })
      .then((response) => response.data);
  },

  async getMobileAccounts(filters: Object) {
    return instance
      .get("account-mobile/", {
        params: filters,
      })
      .then((response) => response.data);
  },

  async getClientAccountStatement(filters: Object, id: any) {
    return instance
      .get(`client-accounts/${id}/statement/`, {
        params: filters,
      })
      .then((response) => response.data);
  },

  async getClientStatement(filters: Object, id: any) {
    return instance
      .get(`client/${id}/statement/`, {
        params: filters,
      })
      .then((response) => response.data);
  },

  async deactivateClientAccount(payload: any, id: string) {
    console.log(payload);
    return instance
      .patch(`client-accounts/${id}/`, payload)
      .then((response) => response.data);
  },

  async addClientAccount(payload: any) {
    console.log(payload);
    return instance
      .post("client/add-account/", payload)
      .then((response) => response.data);
  },

  async addClientBusiness(payload: any) {
    console.log(payload);
    return instance
      .post("business/", payload)
      .then((response) => response.data);
  },

  async updateClientBusiness(payload: any, id: string) {
    console.log(payload);
    return instance
      .patch(`business/${id}/`, payload)
      .then((response) => response.data);
  },

  async deleteClientBusiness(id: string) {
    return instance.delete(`business/${id}/`).then((response) => response.data);
  },


  async getTournamentParticipants(id: string) {
    return instance
      .get(`tournament/${id}/participants/`)
      .then((response) => response.data);
  },
};

import { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import { Box, useTheme, useMediaQuery, Stack, Button } from "@mui/material";
import { RegisterService } from "./services/Register.service";
import { toast } from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import { H1, Paragraph, Small } from "../../../components/Typography";
import FormFactory from "../../../components/UI/FormFactory";
import { UserFormValidations } from "../../Users/UserFormValidations";
import { UserFormFields } from "../../Users/UserFormFields";
import { primary } from "../../../theme/themeColors";
import LoadingButton from "@mui/lab/LoadingButton";
import React from "react";
import { IInstitutionResults } from "../../Institution/Institution.interface";
import { InstitutionService } from "../../Institution/Institution.service";


const Register = () => {
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const formRef = useRef<HTMLFormElement | null>(null);

  const [institutions, setInsitutions] = React.useState<IInstitutionResults>({
    count: 0,
    results: [],
  });
  const [searchQuery, setSearchQuery] = React.useState<string>("");

  const navigate = useNavigate();

  const formFields = UserFormFields({
    institutions: (institutions?.results || []).map((item) => ({
      value: item.id,
      label: item.name,
    })),
  });

  const submitData = async (values: any) => {
    try {
      // Construct the profile object
      const profile = {
        gender: values?.gender,
        date_of_birth: values?.date_of_birth,
        country: values?.country,
      };
      values["profile"] = profile;

      const response = await RegisterService.Register(values);
      setLoading(false);
      if (response.status === 200) {
      }
      console.log(response);
      formik.resetForm();
      toast.success("Account created successfully!");
      navigate("/login", {
        state: {
          message:
            "Your account has been successfully created. Please login to continue...",
        },
      });
    } catch (error: any) {
      toast.error(
        error.message || "An error occurred while creating the account."
      );
    }
  };

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      phone_number: "",
      profile: {
        date_of_birth: "",
        gender: "",
        country: "",
        state: "",
        city: "",
        current_university: "",
        student_status: "",
        alumni_university: "",
        affiliate_organization: "",
        attachment_link: "",
      },
    },
    onSubmit: submitData,
    validationSchema: UserFormValidations,
    validateOnChange: false,
    validateOnMount: false,
    validateOnBlur: false,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const resp: IInstitutionResults =
          await InstitutionService.getInstitutions(searchQuery);
        setInsitutions(resp);
      } catch (error) {
        console.error("Error fetching Teams:", error);
      }
    };

    fetchData();
  }, []);

  const handleButtonClick = () => {
    if (formRef.current) {
      formRef.current.dispatchEvent(
        new Event("submit", { cancelable: true, bubbles: true })
      );
    }
  };

  return (
    <Box
      sx={{
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box sx={{ width: "100%", px: isMobile ? 4 : 4 }}>
        <Stack spacing={1}>
          <H1 fontSize={28} fontWeight={800} mb={3}>
            <span style={{ color: primary.main }}>Register</span>
          </H1>
          <H1 fontSize={16} fontWeight={700}>
            Register to create and account on Afritab!
          </H1>
          <Paragraph pb={3} fontSize={12}>
            Revolutionizing Debate
          </Paragraph>
        </Stack>

        <form
          ref={formRef}
          onSubmit={formik.handleSubmit}
          encType="multipart/form-data"
        >
          <Box sx={{ width: "100%" }}>
            <FormFactory
              others={{ sx: { marginBottom: "0rem" } }}
              formikInstance={formik}
              formFields={formFields}
              validationSchema={UserFormValidations}
            />
          </Box>

          <Box sx={{ mt: 4 }}>
            {loading ? (
              <LoadingButton
                loading
                variant="contained"
                sx={{ bgcolor: "#242649" }}
              >
                Submitting...
              </LoadingButton>
            ) : (
              <Button
                type="button"
                variant="contained"
                sx={{ bgcolor: "#242649" }}
                onClick={handleButtonClick}
              >
                Submit
              </Button>
            )}
          </Box>
        </form>
        <Small
          margin="auto"
          mt={3}
          style={{ display: "block" }}
          color="text.disabled"
        >
          Already have an account?{" "}
          <Link to="/login">
            <Small color="primary.main">Login Now</Small>
          </Link>
        </Small>
      </Box>
    </Box>
  );
};

export default Register;

import debate1 from "./login/Group-discussion-bro.svg";
import debate2 from "./login/Political-debate-amico.svg";
import debate3 from "./login/Political-debate-bro.svg";
import debate4 from "./login/Political-debate-cuate.svg";
import debate5 from "./login/Political-debate-rafiki.svg";

// Login Slider
export const Debate1 = debate1;
export const Debate2 = debate2;
export const Debate3 = debate3;
export const Debate4 = debate4;
export const Debate5 = debate5;

import instance from "../../api";


export const InstitutionService = {
    async getInstitutions(filters: Object) {
        return instance.get('institution/', {
            params: filters
        }).then((response) => response.data)
    },

    async getInstitutionDetails(id: any) {
        return instance.get(`institution/${id}/`).then((response) => response.data)
    },

    // async createInstitutions(payload: Object) {
    //     console.log(payload)
    //     return instance.post('institution/', payload).then((response) => response.data)
    // }, 
    async createInstitutions(payload: any) {
        const formData = new FormData();

        // Assuming payload is an object containing key-value pairs
        for (const key in payload) {
            // if(key === 'logo'){
            //     const photo = payload[key]
            //     const photoFile = {
            //         uri:  photo.uri,
            //         type: 'image/jpeg',
            //         name: 'image',
            //       };
            //       payload.append('logo', photoFile);

            // } else {
            //     formData.append(key, payload[key]);
            // }
            formData.append(key, payload[key]);
        }
    
        console.log(formData); // Optional: Check the formData before sending
    
        return instance.post('institution/', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then((response) => response.data);
    },    

    async updateInstitutions(payload: Object, id: string) {
        console.log(payload)
        return instance.patch(`institution/${id}/`, payload).then((response) => response.data)
    },

    async deleteInstitutions(id: string) {
        console.log(id)
        return instance.delete(`institution/${id}/`).then((response) => response.data)
    }
}